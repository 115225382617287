var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "text--black", attrs: { "bg-variant": "light" } },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-form",
            { staticClass: "justify-content-center", attrs: { inline: "" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mx-3 mt-2",
                  attrs: {
                    label: "Industry",
                    "label-cols": "auto",
                    disabled: _vm.loading
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.industries,
                      size: "sm",
                      required: ""
                    },
                    model: {
                      value: _vm.form.industry_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "industry_id", $$v)
                      },
                      expression: "form.industry_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mx-3 mt-2",
                  attrs: {
                    label: "Year",
                    "label-cols": "auto",
                    disabled: _vm.loading
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.years, size: "sm", required: "" },
                    model: {
                      value: _vm.form.year,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "year", $$v)
                      },
                      expression: "form.year"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mx-3 mt-2",
                  attrs: {
                    label: "Quarter",
                    "label-cols": "auto",
                    disabled: _vm.loading
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.quarters, size: "sm", required: "" },
                    model: {
                      value: _vm.form.quarter,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "quarter", $$v)
                      },
                      expression: "form.quarter"
                    }
                  })
                ],
                1
              ),
              _vm.bucketsForIndustry && _vm.bucketsForIndustry.length > 0
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "mx-3 mt-2",
                      attrs: {
                        label: "Bucket",
                        "label-cols": "auto",
                        disabled: _vm.loading
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.bucketsForIndustry, size: "sm" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("All")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1544529727
                        ),
                        model: {
                          value: _vm.form.industryrevenuebucket_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "industryrevenuebucket_id", $$v)
                          },
                          expression: "form.industryrevenuebucket_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.form.companies && _vm.form.companies.length > 0
            ? _c(
                "div",
                [
                  _c("AvailableCompanies", {
                    attrs: { companies: _vm.form.companies },
                    on: {
                      update: function(val) {
                        _vm.form.companies = val
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.companies && _vm.form.companies.length > 0
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "success",
                        disabled: !_vm.hasCompanySelected
                      },
                      on: { click: _vm.generateReport }
                    },
                    [_vm._v(" Generate Report ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        type: "button",
                        variant: "info",
                        disabled: !_vm.hasCompanySelected
                      },
                      on: { click: _vm.exportCSV }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "download" } }),
                      _vm._v(" Export ")
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.form && _vm.record
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-3", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        [
                          _c("b-card-title", { staticClass: "text-left m-0" }, [
                            _vm._v(
                              _vm._s(_vm.record.industry.name) +
                                " Industry Averages for " +
                                _vm._s(_vm.form.year)
                            ),
                            _vm.form.quarter
                              ? _c("span", [
                                  _vm._v(" Q" + _vm._s(_vm.form.quarter))
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _vm.record.averages.length
                        ? _c("b-table", {
                            staticClass: "mb-0 small",
                            attrs: {
                              "sticky-header": "",
                              items: _vm.record.averages,
                              fields: _vm.fieldsaverages,
                              outlined: "",
                              bordered: "",
                              small: "",
                              striped: "",
                              hover: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(total)",
                                  fn: function(data) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              Math.abs(data.item.total)
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(percentofincome)",
                                  fn: function(data) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.percentofincome) +
                                          "% "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              734393917
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }